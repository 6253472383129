
import { mapState } from 'vuex'
import { safeJsonParse } from '~/utils'

const PopupBox = () => import('~/containers/NewHome/PopupBox/index.vue')
const Claim = () => import('~/containers/NewHome/Claim.vue')
const LandingPage = () => import('~/containers/LandingPage/LandingPage.vue')
const HomeDashboard = () => import('~/containers/NewHome/HomeDashboard.vue')

export default {
  components: {
    PopupBox,
    // stagging purpose
    // eslint-disable-next-line vue/no-unused-components
    Claim,
    LandingPage,
    HomeDashboard,
  },
  data() {
    return {
      isMounted: false,
      loadingPopularServices: false,
      popularServices: [],
      sellerSections: [],
      showPopup: false,
      popupData: null,
      canonical: 'https://ganknow.com',
    }
  },
  fetch() {
    this.canonical = this.$config.baseURL
    if (this.$auth.$storage.getUniversal('challengeToken')) {
      this.$router.push('/login/challenge')
    }
  },
  // fetchOnServer: false,
  head() {
    return {
      title: this.pageTitle,
      link: [
        {
          rel: 'canonical',
          href: this.canonical,
        },
      ],
    }
  },
  computed: {
    ...mapState({
      countryUser: state => state.settings.countryUser,
    }),
    isMobile() {
      const { context } = this.$nuxt
      return context.isMobileOrTablet
    },
    pageTitle() {
      return this.$auth.loggedIn ? 'Gank - Dashboard' : 'Gank - Earn More From Your Content'
    },
  },
  created() {
    this.isMounted = true
  },
  mounted() {
    this.canonical = window.location.origin
    this.$nextTick(() => {
      this.initializePopup()
    })
  },
  methods: {
    toggleShowPopup() {
      this.showPopup = !this.showPopup
    },
    async initializePopup() {
      // * prevent remote config error by try to open indexedDB
      // * remote config will error if users open web using firefox with incognito mode
      const getPopupCookie = this.$cookies.get('popup-box')
      const checkIndexedDB = window.indexedDB.open('checking')
      checkIndexedDB.onsuccess = async () => {
        try {
          console.log('open remote config')
          await this.$fire.remoteConfigReady()
          await this.$fire.remoteConfig.fetchAndActivate()
          const getConfig = this.$fire.remoteConfig.getValue(process.env.NUXT_ENV_POPUP_REMOTE)
          // eslint-disable-next-line no-underscore-dangle
          const parseConfig = safeJsonParse(getConfig._value, {})
          if (parseConfig.active && typeof getPopupCookie === 'undefined') {
            this.popupData = parseConfig
            this.toggleShowPopup()
          }
        } catch (_) {
        }
      }
    },
  },
}
